<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Septiembre 2024                                                 ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">
    <!-- Fitros -->
    <section>
      <div class="d-flex">
        <!-- Filtrar por fecha inicial -->
        <v-menu ref="menuFechaInicio" v-model="menuFechaInicio" transition="scale-transition" min-width="290px"
          :close-on-content-click="false" :return-value.sync="filtro.fechaInicio" :nudge-top="25" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field class="me-2 filtro" v-model="filtro.fechaInicio" label="Fecha inicial *" v-bind="attrs" v-on="on"
              outlined dense readonly>
            </v-text-field>
          </template>
          <v-date-picker v-model="filtro.fechaInicio" :first-day-of-week="1" :max="currentDate" locale="es-co"
            no-title scrollable @input="saveFechaInicial">
            <v-btn text color="primary" @click="cleanFechaInicial">Borrar</v-btn>
          </v-date-picker>
        </v-menu>

        <!-- Filtrar por fecha final -->
        <v-menu ref="menuFechaFinal" v-model="menuFechaFinal" transition="scale-transition" min-width="290px"
          :close-on-content-click="false" :return-value.sync="filtro.fechaFin" :nudge-top="25" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field class="me-2 filtro" v-model="filtro.fechaFin" label="Fecha final *" v-bind="attrs" v-on="on"
              :error-messages="rangoFechas" outlined dense readonly>
            </v-text-field>
          </template>
          <v-date-picker v-model="filtro.fechaFin" :first-day-of-week="1" :max="currentDate" locale="es-co" no-title
            scrollable @input="saveFechaFinal">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="cleanFechaFinal">Borrar</v-btn>
          </v-date-picker>
        </v-menu>

        <!-- Botón para generar reporte -->
        <v-tooltip left :color="permitirGenerarReporte ? 'primary' : 'grey'" :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small fab :color="permitirGenerarReporte ? 'primary' : 'grey'" 
              v-bind="attrs" v-on="on" @click="permitirGenerarReporte && generarReporte()">
              <v-icon color="white"> download </v-icon>
            </v-btn>
          </template>
          <span>Generar reporte</span>
        </v-tooltip>
      </div>
    </section>

    <!-- Tabla que almacena las solicitudes -->
    <v-data-table class="elevation" :items="tablaSolicitudes.items" :loading="tablaSolicitudes.loading"
      fixed-header :headers="tablaSolicitudes.headers" :footer-props="tablaSolicitudes.footerProps"
      :items-per-page.sync="tablaSolicitudes.itemsPerPage" :page.sync="tablaSolicitudes.page"
      :server-items-length="tablaSolicitudes.totalPage" height="64vh">
      <template v-slot:[`item.fechaSolicitud`]="{ item }">
        {{ formatoFecha(item.fechaSolicitud) }}
      </template>
      <template v-slot:[`item.tipoSolicitud`]="{ item }">
        {{ item.tipoSolicitud === 'AMBULATORY' ? 'Ambulatorio' : 'Hospitalario' }}
      </template>
      <template v-slot:[`item.estadoSeguimiento`]="{ item }">
        {{ obtenerEstado(item.estadoSeguimiento) }}
      </template>
      <template v-slot:[`item.prioritario`]="{ item }">
        {{ item.prioritario ? 'Si' : 'No' }}
      </template>
      <template v-slot:[`item.cohorte`]="{ item }">
        {{ item.cohorte ? 'Si' : 'No' }}
      </template>
      <template v-slot:[`item.notaTecnica`]="{ item }">
        {{ item.notaTecnica ? 'Si' : 'No' }}
      </template>
      <template v-slot:[`item.autoinmune`]="{ item }">
        {{ item.autoinmune ? 'Si' : 'No' }}
      </template>
      <template v-slot:footer.page-text="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
    </v-data-table>

  </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";
import { Role } from "@/router/role.js";
import moment from "moment";
import Papa from 'papaparse';

/**
* Esta función toma una matriz de matrices (una matriz bidimensional) 
* donde cada sub-matriz (o fila) representa una fila de datos en el CSV.
* Cada elemento dentro de las sub-matrices se une con un delimitador 
* (en este caso un punto y coma ';') y cada fila se une con un salto de línea.
* 
* @param {Array} data - Matriz bidimensional que contiene los datos.
* @returns {string} - Cadena representando los datos en formato CSV.
*/
function arrayToCSV(data) {
  return data.map(row => row.join(";")).join("\n");
}

/**
* Esta función crea un archivo en formato CSV a partir de una cadena 
* y luego inicia una descarga en el navegador del usuario para ese archivo.
* 
* @param {string} csvData - Cadena que contiene los datos en formato CSV.
* @param {string} filename - Nombre que se asignará al archivo descargado.
*/
function downloadCSV(csvData, filename) {
  const blob = new Blob([csvData], { type: "text/csv" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default {
  name: "Solicitudes",
  data() {
    return {
      alert: false,
      rangoFechas: '',
      menuFechaInicio: false,
      menuFechaFinal: false,
      filtro: {
        fechaInicio: null,
        fechaFin: null,
      },
      mensajeError: '',
      userRoles: {},
      roles: Role,
      tablaSolicitudes: {
        items: [],
        loading: false,
        headers: [
          { text: "FECHA SOLICITUD", value:'fechaSolicitud', width: '5rem', align: "left", sortable: false },
          { text: "NÚMERO SOLICITUD",value: 'idSolicitud', align: "center", sortable: false },
          { text: "TIPO SOLICITUD", value: 'tipoSolicitud', align: "center", sortable: false },
          { text: "ESTADO", value: 'estadoSeguimiento', align: "center", sortable: false },
          { text: "CUPS", value:'cups', align: "center", sortable: false },
          { text: "DETALLE DE CUPS", value: 'descripcionCups', sortable: false },
          { text: "TIPO DOCUMENTO", value: 'tipoDocumento', width: "16%", sortable: false },
          { text: "DOCUMENTO", value: 'numeroDocumento', align: "left", sortable: false },
          { text: "PRIMER NOMBRE", value: 'primerNombre', sortable: false },
          { text: "SEGUNDO NOMBRE", value: 'segundoNombre', sortable: false },
          { text: "PRIMER APELLIDO", value: 'primerApellido', sortable: false },
          { text: "SEGUNDO APELLIDO", value: 'segundoApellido', sortable: false },
          { text: "NÚMERO TELEFÓNICO", value: 'numeroCelular', sortable: false },
          { text: "PRIORITARIO", value: 'prioritario', align: 'center', sortable: false },
          { text: "COHORTE", value: 'cohorte', align: "center", sortable: false },
          { text: "NOTA TÉCNICA", value: 'notaTecnica', align: "center", sortable: false },
          { text: "AUTOINMUNE", value: 'autoinmune', align: "center", sortable: false },
          { text: "FECHA CITA", value: 'fechaCita', align: "center", sortable: false },
          { text: "HORA CITA", value: 'horaCita', align: "center", sortable: false },
          { text: "RADICADO CONEXIONES", value: 'radicadoConexiones', align: "center", sortable: false },
          { text: "IPS SOLICITANTE", value: 'ipsSolicitante', sortable: false },
          { text: "IPS PRESTADOR", value: 'prestadorAsignado', sortable: false },
          { text: "USUARIO SOLICITUD", value: 'usuarioCreaSolicitud', align: "center", sortable: false },
        ],
        footerProps: {
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Items por página:',
        'show-current-page': true,
        'show-first-last-page': true
        },
      itemsPerPage: 10,
      page: 1,
      totalPage: 0,
      },
      estados: [
        { text: 'Creado', value: 'CREATED' },
        { text: 'Aceptado', value: 'ACCEPTED' },
        { text: 'Rechazado', value: 'REFUSED' },
        { text: 'Asignado a IPS', value: 'ASSIGNED' },
        { text: 'Gestionado', value: 'MANAGED' },
        { text: 'Anulado', value: 'CANCELED' },
        { text: 'Atendido', value: 'SERVED' },
        { text: 'Finalizado', value: 'FINALIZED' },
        { text: 'Actualizado', value: 'UPDATED' },
        { text: 'Devuelto', value: 'RETURNED' },
      ],
      rules: {
        required: [
          v => !!v || "Este campo es requerido.",
        ],
      },
    }
  },
  mounted() {
    this.listarSolicitudes();
  },
  computed: {
    ...mapState(["auth", "notify", "busy"]),
    /**
     * Función computada que devuelve la fecha actual en formato ISO (YYYY-MM-DD)
     * para que en el v-date-picker no seleccione fechas posteriores a las del día actual. 
     */
    currentDate() {
      return new Date().toISOString().split('T')[0];
    },
    /**
     * Valida que los filtros estén llenos y hayan resultados para permitir generar el reporte.
     */
    permitirGenerarReporte() {
      return this.filtro.fechaInicio && this.filtro.fechaFin && !this.rangoFechas && this.tablaSolicitudes.items.length > 0;
    }
  },
  watch: {
    /**
     * Este watcher se activa cuando alguna propiedad del objeto `filtro` cambia.
     * Es "deep", por lo que observa cambios en propiedades anidadas dentro de `filtro`.
     * Al detectar cambios, se encarga de desactivar la alerta (`alert` se establece en false).
     */
    'filtro.fechaInicio' : function () {
      if ((this.filtro.fechaInicio && this.filtro.fechaFin) || (!this.filtro.fechaInicio && !this.filtro.fechaFin)) {
        this.tablaSolicitudes.page = 1;
        this.listarSolicitudes();
      }
    },
    'filtro.fechaFin' : function () {
      if ((this.filtro.fechaInicio && this.filtro.fechaFin) || (!this.filtro.fechaInicio && !this.filtro.fechaFin)) {
        this.tablaSolicitudes.page = 1;
        this.listarSolicitudes();
      }
    },
    'tablaSolicitudes.page': function () {
      this.listarSolicitudes();
    },
    'tablaSolicitudes.itemsPerPage': function () {
      this.tablaSolicitudes.page = 1;
      this.listarSolicitudes(); 
    }
  },
  methods: {
    ...mapMutations([
      "updateAuth",
      "hideNotify",
      "showSuccess",
      "showBusy",
      "hideBusy",
    ]),
    /**
     *  Método que lista las solicitudes en la tabla.
     */
    listarSolicitudes() {
      if (this.rangoFechas === '') {
        this.$http.get(`msa-reports/api/solicitud-ruta/listar`, {
          params: {
            fechaInicial: this.filtro.fechaInicio ? this.filtro.fechaInicio : null,
            fechaFinal: this.filtro.fechaFin ? this.filtro.fechaFin : null,
            page: this.tablaSolicitudes.page -1,
            size: this.tablaSolicitudes.itemsPerPage
          }
        }).then(response => {
          this.tablaSolicitudes.items = response.data.content;
          this.tablaSolicitudes.totalPage = response.data.totalElements;          
        }).catch(error => {
          console.log(error);
        })
      }
    },
    /**
     * Este método verifica que la fecha inicial (`fechaUno`) sea anterior a la fecha final (`fechaDos`). Además, 
     * se asegura de que el rango de fechas seleccionadas no exceda un mes. Si alguna de estas condiciones no se cumple, 
     * establece un mensaje de error correspondiente y marca el estado como inválido (`invalid = true`).
     */
    validacionesFechas() {
      const fechaUno = new Date(`${this.filtro.fechaInicio}T00:00:00-05:00`);
      const fechaDos = new Date(`${this.filtro.fechaFin}T00:00:00-05:00`);

      if (fechaUno > fechaDos) {
        this.rangoFechas = 'La fecha final debe ser mayor a la fecha inicial';
        return;
      }

      let rangoUnMes = new Date(fechaUno);
      rangoUnMes.setMonth(rangoUnMes.getMonth() + 1);

      if (fechaUno.getDate() === 1) {
        rangoUnMes.setDate(0);
      }

      if (fechaDos > rangoUnMes) {
        this.rangoFechas = 'Las fechas seleccionadas no deben exceder un mes';
      } else {
        this.rangoFechas = '';
      }
    },
    /**
     * Método que guarda la fecha inicial en el campo de entrada.
     */
    saveFechaInicial() {
      this.$refs.menuFechaInicio.save(this.filtro.fechaInicio);
      this.validacionesFechas();
    },
    /**
     * Método que guarda la fecha final en el campo de entrada.
     */
    saveFechaFinal() {
      this.$refs.menuFechaFinal.save(this.filtro.fechaFin);
      this.validacionesFechas();
    },
    /**
     * Método que limpia el campo de entrada del filtro fecha inicial.
     */
    cleanFechaInicial() {
      this.filtro.fechaInicio = null;
      this.$refs.menuFechaInicio.save('');
      this.rangoFechas = '';
    },
    /**
     * Método que limpia el campo de entrada del filtro fecha final.
     */
    cleanFechaFinal() {
      this.filtro.fechaFin = null;
      this.$refs.menuFechaFinal.save('');
      this.rangoFechas = '';
    },
    /**
     * Método que formatea una fecha dada en el formato 'DD/MM/YYYY hh:mm:ss a'.
     * @param fecha - La fecha que se va a formatear.
     */
    formatoFecha(fecha) {
      return moment(fecha).format('DD/MM/YYYY hh:mm:ss a');
    },
    /**
     * Método que busca el estado correspondiente a un valor en un arreglo de estados.
     * @param item - El valor del estado que se desea buscar en el arreglo de estados.
     */
    obtenerEstado(item) {
      const estado = this.estados.find(estado => estado.value === item);
      return estado ? estado.text : '';
    },
    /**
     * Método para generar y descargar un archivo CSV con los datos de las solicitudes de ruta.
     */
    generarReporte() {
      this.$http.get(`msa-reports/api/solicitud-ruta/generar-reporte`, {
        responseType: 'blob',
        params: {
          fechaInicial: this.filtro.fechaInicio ? this.filtro.fechaInicio : null,
          fechaFinal: this.filtro.fechaFin ? this.filtro.fechaFin : null,
          page: 0,
          size: this.tablaSolicitudes.totalPage
        }
      }).then(response => {
        const filename = `informe_solicitudes_ruta_${this.filtro.fechaInicio.replace(/-/g, '_')}_al_${this.filtro.fechaFin.replace(/-/g, '_')}.csv`;
        Papa.parse(response.data, {
          complete: (results) => {
            const csvData = arrayToCSV(results.data);
            if (results.data.length > 2) {
              downloadCSV(csvData, filename);
            }
          }
        });
      }).catch(error => {
        console.log(error);
      })
    },
  }
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 86vh;
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

.filtro {
  width: 50%;
}
</style>
